const state = {
    type: "",
    questions: [],
    answers: [],
    activeQuestionIndex: 0,
    testCode: {},
};
const getters = {
    partialResponseUrl(state) {
        return state.type === "ivq" ? route('test-code.ivq-progress', {test_code: state.testCode.id }) : route('test-code.rheti-progress', {test_code: state.testCode.id })
    },
    isQuestionAnswered(state) {
        if (state.type === "ivq") {
            if (state.answers[state.activeQuestionIndex]) {
                return state.answers[state.activeQuestionIndex].most_like_id !== null &&
                    state.answers[state.activeQuestionIndex].most_unlike_id !== null;
            }
            return false;
        } else if (state.type === "rheti") {
            if (state.answers[state.activeQuestionIndex]) {
                return state.answers[state.activeQuestionIndex].answer_id !== null;
            }
            return false;
        }
    },
    rhetiShowSubmitButton(state, getters) {
        return state.type === 'rheti' && getters.allQuestionsAnswered && state.activeQuestionIndex === 143
    },
    ivqLastQuestion(state) {
      return state.type === 'ivq' && state.activeQuestionIndex === 37;
    },
    allQuestionsAnswered(state) {
        return state.answers.length === state.questions.length
    },
    showNextButton(state) {
        if (state.type === 'rheti') {
            return state.activeQuestionIndex + 1 < state.questions.length;
        } else if(state.type === "ivq") {
            return state.activeQuestionIndex + 1 <= state.questions.length;
        }
    },
};
const mutations = {
    updateType(state, payload) {
        state.type = payload;
    },
    updateAnswers(state, payload) {
        state.answers = payload;
    },
    updateTestCode(state, payload) {
      state.testCode = payload;
    },
    pushNewAnswer(state, payload) {
        state.answers.push(payload);
    },
    updateExistingAnswer(state, {answerData, answerIndex}) {
        if (state.type === "ivq") {
            state.answers[answerIndex].most_like_id = answerData.most_like_id;
            state.answers[answerIndex].most_unlike_id = answerData.most_unlike_id;
        } else if (state.type === "rheti") {
            state.answers[answerIndex].answer_id = answerData.answer_id;
        }
    },
    updateQuestions(state, payload) {
        state.questions = payload;
    },
    updateActiveQuestionIndex(state, payload) {
        state.activeQuestionIndex = payload;
    },
};
const actions = {
    updateAnswers({state, commit}, payload) {
        const answers = Object.entries(payload).map(([key, value]) => {
            const question_id = parseInt(key);
            if (state.type === "ivq") {
                return {
                    question_id,
                    most_like_id: value[0],
                    most_unlike_id: value[1],
                };
            } else if (state.type === "rheti") {
                return {
                    question_id,
                    answer_id: value,
                };
            }
        });
        commit('updateAnswers', answers);
    },
    async savePartialResponse({commit, getters}, payload) {
        await window.axios.put(getters.partialResponseUrl, payload);
    },
    async resetProgress({state}) {
        try {
            const {data} = await window.axios.post(route('test-code.reset-progress', {test_code: state.testCode.id}));
            return data.response_json;
        } catch (e) {
            console.log(e);
        }
    },
    updateAnswer({state, commit}, {question_id, answerData}) {
        const answerIndex = state.answers.findIndex((answer) => {
            return answer.question_id === question_id;
        });

        let payload = {};
        if (state.type === "ivq") {
            payload = {
                question_id: question_id,
                most_like_id: answerData.most_like_id,
                most_unlike_id: answerData.most_unlike_id,
            }
        } else if (state.type === "rheti") {
            payload = {
                question_id: question_id,
                answer_id: answerData.answer_id,
            }
        }

        if (answerIndex === -1) {
            commit('pushNewAnswer', payload)
        } else {
            commit('updateExistingAnswer', {
                answerData: answerData,
                answerIndex: answerIndex,
            })
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}