<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="20">
        <div class="panel panel-deafult">
          <div class="panel-heading panel-custom">
            <h3>
              <img
                :src="type === 'ivq' ? ivqImage : rhetiImage"
                class="test-logo"
              />{{ test.description }}
            </h3>
          </div>
          <div class="panel-body question-container">
            <ivq-question
              v-if="type === 'ivq' && !ivqLastQuestion"
              @questionAnswered="questionAnswered"
              :localText="localText"
            >
            </ivq-question>
            <ivq-final
              v-if="ivqLastQuestion"
              @testFinished="submitTest"
              :localText="localText"
              :submitting="submitting"
            >
            </ivq-final>
            <rheti-question
              v-if="type === 'rheti'"
              :totalQuestions="test.questions.length"
              @questionAnswered="questionAnswered"
              :localText="localText"
            >
            </rheti-question>
          </div>
          <el-row class="text-center">
            <el-col
              :span="24"
              class="
                carousel-navigation
                d-flex
                items-center
                justify-center
                gap-1
              "
            >
              <!--Previous Button-->
              <el-button @click="prev" :disabled="activeQuestionIndex === 0">
                <span class="glyphicon glyphicon-chevron-left"></span>
                {{ localText.previous }}
              </el-button>
              <!--Question Progress Count-->
              <div v-if="activeQuestionIndex + 1 <= questions.length">
                <span
                  >{{ activeQuestionIndex + 1 }}/{{ questions.length }}</span
                >
              </div>
              <!--Next Button-->
              <el-button
                @click="next"
                v-if="showNextButton"
                :disabled="!isQuestionAnswered || buttonDisabled"
              >
                {{ localText.next }}
                <span class="glyphicon glyphicon-chevron-right"></span>
              </el-button>
              <!--Submit Button-->
              <el-button
                :loading="submitting"
                v-if="rhetiShowSubmitButton"
                type="primary"
                @click="submitTest(null)"
                >Submit Test
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style scoped>
.d-flex {
  display: flex;
}

.gap-1 {
  gap: 15px;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

@media (max-width: 768px) {
  .question-container {
    height: 450px;
  }
}

@media (min-width: 770px) {
  .question-container {
    height: 350px;
  }
}
</style>
<script>
import {mapActions, mapMutations, mapGetters, mapState} from "vuex";
import ivqImage from "../../../../images/ivq-logo.png";
import rhetiImage from "../../../../images/rheti-logo.png";

export default {
  data() {
    return {
      loading: false,
      submitting: false,
      buttonDisabled: false,
      ivqImage: ivqImage,
      rhetiImage: rhetiImage,
    };
  },
  props: [
    "test",
    "testCode",
    "environment",
    "localText",
    "name",
    "type",
    "testResponse",
  ],
  computed: {
    ...mapState("test", ["questions", "answers", "activeQuestionIndex"]),
    ...mapGetters("test", [
      "isQuestionAnswered",
      "showNextButton",
      "rhetiShowSubmitButton",
      "ivqLastQuestion",
    ]),
  },
  methods: {
    ...mapMutations("test", [
      "updateQuestions",
      "updateType",
      "updateTestCode",
      "updateActiveQuestionIndex",
    ]),
    ...mapActions("test", [
      "updateAnswers",
      "savePartialResponse",
      "updateAnswer",
      "resetProgress",
    ]),
    questionAnswered(answer) {
      const {question_id} = answer;
      if (this.type === "ivq") {
        const {most_like_id, most_unlike_id} = answer;
        this.updateAnswer({question_id, answerData: {most_like_id, most_unlike_id}});
      } else if (this.type === "rheti") {
        const {answer_id} = answer;
        this.updateAnswer({question_id, answerData: {answer_id}});
      }
    },
    prev() {
      this.updateActiveQuestionIndex(this.activeQuestionIndex - 1);
      window.scrollTo(0, 0);
    },
    next() {
      this.submitPartialResponse();
    },
    async submitPartialResponse() {
      try {
        this.buttonDisabled = true;
        const question_id = this.questions[this.activeQuestionIndex].id;
        const answer = this.answers.find(answer => answer.question_id === question_id);

        if (!answer) {
          throw new Error("No answer found for the current question. Please try again.");
        }

        const payload = {
          test_code_id: this.testCode.id,
          question_id,
          hash: this.testResponse.hash,
          ...this.type === "ivq"
              ? {most_like_id: answer.most_like_id, most_unlike_id: answer.most_unlike_id}
              : {answer_id: answer.answer_id}
        };

        await this.savePartialResponse(payload);
        this.updateActiveQuestionIndex(this.activeQuestionIndex + 1);
      } catch (error) {
        this.$notify({title: "Submission Error", message: "Could not save your answer. Please try again."});
      } finally {
        this.buttonDisabled = false;
        window.scrollTo(0, 0);
      }
    },
    async submitTest(finalResponses) {
      try {
        this.submitting = true;
        let responses = this.type === "rheti"
            ? this.answers.map(answer => ({[`question_${answer.question_id}`]: answer.answer_id}))
            : this.answers;

        if (this.type === "rheti") {
          const question_id = this.questions[this.activeQuestionIndex].id;
          const answer = this.answers.find(answer => answer.question_id === question_id);

          if (answer) {
            const payload = {
              test_code_id: this.testCode.id,
              question_id,
              hash: this.testResponse.hash,
              answer_id: answer.answer_id
            };
            await this.savePartialResponse(payload);
          }
        }

        this.submitResponses(responses, finalResponses);
      } catch (error) {
        this.submitting = false;
        this.$notify({title: "Submission Error", message: "Could not submit your test. Please try again."});
      }
    },
    submitResponses(responses, finalResponses) {
      window.axios.post(`/test-code/${this.testCode.id}/show`, {responses, final: finalResponses})
          .then(({data}) => {
            if (data.status === "success") {
              window.location.href = data.url;
            } else {
              this.submitting = false;
              this.$notify({title: data.title, message: data.message});
            }
          })
          .catch(error => {
            this.submitting = false;
            this.$notify({
              title: "Final Submission Error",
              message: "Could not finalize your test submission. Please try again."
            });
          });
    },
  },
  async created() {
    this.updateType(this.type);
    this.updateTestCode(this.testCode);
    this.updateQuestions(this.test.questions);
    this.updateAnswers(this.testResponse.response_json)

    if (this.type === "ivq") {
      this.updateActiveQuestionIndex(this.answers.length);
    } else if (this.type === "rheti") {
      this.updateActiveQuestionIndex(
          this.answers.length === this.questions.length
              ? this.answers.length - 1
              : this.answers.length
      );
    }
  }

};
</script>
<style>
.el-card__header {
  background-color: #f5f5f5;
}

.panel-custom {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
</style>
